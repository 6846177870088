import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  #deferredPrompt

  static targets = ['spinner', 'incompatible', 'install', 'alternateInstall', 'installing', 'issues']

  static values = {
    serviceWorkerUrl: String,
    pwaUrl: String,
    installTimeout: {
      type: Number,
      default: 3000
    }
  }

  connect () {
    super.connect()

    if ('serviceWorker' in navigator) {
      this.start()
    } else {
      this.show('incompatible')
    }
  }

  start () {
    // Chrome/edge supports this, maybe firefox, not safari.
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault()

      this.#deferredPrompt = event

      this.show('install')
    })

    window.addEventListener('load', () => {
      navigator.serviceWorker.register(this.serviceWorkerUrlValue)
    })

    new Promise((resolve) => {
      setTimeout(() => { resolve() }, this.installTimeoutValue)
    }).then(() => {
      if (this.#deferredPrompt) {
        // Do nothing because we've received the deferred install prompt and
        // hopefully everything is good now?
      } else {
        // We haven't received the deferred install prompt so let the user know.
        this.show('issues')
      }
    })
  }

  install () {
    this.show('installing')

    this.#deferredPrompt.prompt().then(({ outcome, platform }) => {
      if (outcome === 'dismissed') {
        this.show('alternateInstall')
      }
    })
  }

  show () {
    this.spinnerTarget.classList.toggle('d-none', [...arguments].indexOf('spinner') === -1)
    this.alternateInstallTarget.classList.toggle('d-none', [...arguments].indexOf('alternateInstall') === -1)
    this.installingTarget.classList.toggle('d-none', [...arguments].indexOf('installing') === -1)
    this.installTarget.classList.toggle('d-none', [...arguments].indexOf('install') === -1)
    this.incompatibleTarget.classList.toggle('d-none', [...arguments].indexOf('incompatible') === -1)
    this.issuesTarget.classList.toggle('d-none', [...arguments].indexOf('issues') === -1)
  }
}

import $ from 'jquery' // Make jquery global

import '../common'

import { application } from '../controllers/application'

// Load all controllers all the time?
import controllers from '../controllers/{common,frontend}/*_controller.js'

import consumer from '../channels/consumer'

// Need these from community when editing user profile
import MultiSelectController from '../controllers/community/multi_select_controller'

import AvatarController from '../controllers/community/avatar_controller'
window.jQuery = window.$ = $
controllers.forEach((controller) => {
  application.register(
    controller.name.replace(/\.\.--controllers--(common|admin|community|frontend)--/, ''),
    controller.module.default
  )
})
application.consumer = consumer
application.register('multi-select', MultiSelectController)
application.register('avatar', AvatarController)

// Experimental view transitions
const viewTransitionHandler = (event) => {
  if (document.startViewTransition) {
    const originalRender = event.detail.render
    event.detail.render = (currentElement, newElement) => {
      document.startViewTransition(() => originalRender(currentElement, newElement))
    }
  }
}

window.addEventListener('turbo:before-render', viewTransitionHandler)
window.addEventListener('turbo:before-frame-render', viewTransitionHandler)
